import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import colors from '../../theme/colors';

const sectionContainer = {
    padding: '45px 136px',
    maxWidth: '1440px',
};
const pillarsPompadour = {
    fontWeight: 400,
    color: colors.lightGreen,
    lineHeight: '24.55px',
};
const pillarsWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    height: 'auto',
};
const pillarTitleStyle = {
    color: colors.lightGreen, lineHeight: '26px',
};
const pillarTextStyle = {
    color: colors.lightGreen, lineHeight: '25px',
};

const HomePillars = () => (
    <Box
        style={{
            backgroundColor: colors.violet,
            display: 'flex',
            justifyContent: 'center',
            boxShadow: 'inset 0px 5px 20px 2px rgba(0, 0, 0, 0.15)',
        }}
    >
        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: '24px 24px 0px' }, ...sectionContainer })}>
            <Typography
                variant="h5"
                sx={{ ...pillarsPompadour }}
            >
                Cómo trabajamos
            </Typography>
            <Typography
                variant="h3"
                sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '24px' }, lineHeight: '29px' })}
            >
                Nuestros 3 pilares
            </Typography>
            <Grid container spacing={2} mt={4} sx={{ ...pillarsWrapper }}>

                <Grid
                    item
                    md={4}
                    xs={12}
                    sx={(theme) => ({
                        [theme.breakpoints.up('md')]: {
                            borderRight: `3px ${colors.darkViolet} solid`,
                        },
                        [theme.breakpoints.down('md')]: {
                            borderBottom: `3px ${colors.darkViolet} solid`,
                            paddingBottom: '24px',
                        },
                    })}
                >
                    <Grid container>
                        <Grid item xs={10}>
                            <Box sx={{ height: '94px' }}>
                                <StaticImage src="../../images/design_of_architecture.svg" alt="Diseño" />
                            </Box>
                            <Box mt={4}>
                                <Typography variant="h4" style={{ ...pillarTitleStyle }}>
                                    Diseño de arquitectura y planificación
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h5" style={{ ...pillarTextStyle }}>
                                    Te ayudamos a diseñar la mejor arquitectura para tus aplicaciones considerando los recursos necesarios.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h3" style={{ color: colors.green }}>
                                01
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    md={4}
                    xs={12}
                    sx={(theme) => ({
                        [theme.breakpoints.up('md')]: {
                            borderRight: `3px ${colors.darkViolet} solid`,
                        },
                        [theme.breakpoints.down('md')]: {
                            borderBottom: `3px ${colors.darkViolet} solid`,
                            paddingBottom: '24px',
                        },
                    })}
                >
                    <Grid
                        container
                        sx={(theme) => ({ [theme.breakpoints.up('lg')]: { paddingLeft: '44px' } })}
                    >
                        <Grid item xs={10}>
                            <Box sx={{ height: '94px' }}>
                                <StaticImage src="../../images/calculator.svg" alt="Calculadora" />
                            </Box>
                            <Box mt={4}>
                                <Typography variant="h4" style={{ ...pillarTitleStyle }}>
                                    Precios transparentes y predecibles
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h5" style={{ ...pillarTextStyle }}>
                                    Siempre vas a saber o poder calcular de antemano el precio que vas a pagar en el mes. ¡Sin sorpresas!
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h3" style={{ color: colors.green }}>
                                02
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    md={4}
                    xs={12}
                    sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '24px' } })}
                >
                    <Grid
                        container
                        sx={(theme) => ({ [theme.breakpoints.up('lg')]: { paddingLeft: '44px' } })}
                    >
                        <Grid item xs={10}>
                            <Box sx={{ maxHeight: '94px' }}>
                                <StaticImage src="../../images/headset.svg" alt="Headset" />
                            </Box>
                            <Box mt={4}>
                                <Typography variant="h4" style={{ ...pillarTitleStyle }}>
                                    Soporte personalizado y ágil
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h5" style={{ ...pillarTextStyle }}>
                                    Contamos con un esquema de soporte que nos diferencia de los demás proveedores de nube.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h3" style={{ color: colors.green }}>
                                03
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    </Box>
);

export default HomePillars;
