import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Layout from '../layout/Layout';
import Hero from '../components/home/Hero';
import HomeConsultation from '../components/home/HomeConsultation';
import HomeServices from '../components/home/HomeServices';
import HomeSolutions from '../components/home/HomeSolutions';
import HomePillars from '../components/home/HomePillars';
import ClientsCarrousel from '../components/home/ClientsCarrousel';

const homeConsultationProps = {
    consultationContainer: {
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    mainContainer: {
        padding: '24px 0px 0px',
    },
};

const IndexPage = () => (
    <Layout>
        <Hero scrollTo={scrollTo} />
        <HomeServices scrollTo={scrollTo} />
        <HomeSolutions />
        <HomePillars />
        <ClientsCarrousel />
        <HomeConsultation homeConsultationProps={homeConsultationProps} />
    </Layout>
);

export default IndexPage;
