import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const logoStyle = {
    width: '140px',
    height: '44px',
};

export default [
    <StaticImage src="../images/carrousel/amia.svg" alt="amia" style={logoStyle} />,
    <StaticImage src="../images/carrousel/amm.svg" alt="amm" style={logoStyle} />,
    <StaticImage src="../images/carrousel/megacar.svg" alt="megacar" style={logoStyle} />,
    <StaticImage src="../images/carrousel/ostcara.svg" alt="ostcara" style={logoStyle} />,
    <StaticImage src="../images/carrousel/viamo.svg" alt="viamo" style={logoStyle} />,
    <StaticImage src="../images/carrousel/bonds.svg" alt="bonds" style={logoStyle} />,
    <StaticImage src="../images/carrousel/intersoft.svg" alt="intersoft" style={logoStyle} />,
    <StaticImage src="../images/carrousel/alba_caucion.svg" alt="alba_caucion" style={logoStyle} />,
    <StaticImage src="../images/carrousel/norauto.svg" alt="norauto" style={logoStyle} />,
    <StaticImage src="../images/carrousel/vitcodes.svg" alt="vitcodes" style={logoStyle} />,
    <StaticImage src="../images/carrousel/seguros_medicos.svg" alt="seguros_medicos" style={logoStyle} />,
    <StaticImage src="../images/carrousel/dota.png" alt="dota" style={logoStyle} />,
    <StaticImage src="../images/carrousel/lario.png" alt="lario" style={logoStyle} />,
    <StaticImage src="../images/carrousel/osdop.png" alt="osdop" style={logoStyle} />,
    <StaticImage src="../images/carrousel/simplot.png" alt="simplot" style={logoStyle} />,
    <StaticImage src="../images/carrousel/sur_finanzas.png" alt="sur_finanzas" style={logoStyle} />,
    <StaticImage src="../images/carrousel/trivento.png" alt="trivento" style={logoStyle} />,
];
