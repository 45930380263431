import React, { useState, useRef } from 'react';
import {
    Typography, Box, Button, Grid,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import colors from '../../theme/colors';
import { useIntersection } from '../../helpers/home_types';

const sectionContainer = {
    padding: '60px 136px 45px',
    maxWidth: '1440px',
    width: '100%',
};

const servicesContainer = {
    width: '100%',
    display: 'flex',
};
const servicesWrapper = {
    height: '100%',
    backgroundColor: colors.cyanBlue,
    overflow: 'hidden',
    boxShadow: '0px 2px 4px 0px rgba(255, 255, 255, 0.1) inset',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    display: 'flex',
    alignItems: 'center',
};

const imageContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
};
const titleContainer = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
};
const selectServiceButton = {
    width: '100%',
    justifyContent: 'start',
    textAlign: 'left',
    padding: 0,
};
const arrowIcon = {
    color: colors.green,
    padding: 0,
    transform: 'scale(0.75)',
};
const customH3 = {
    fontWeight: 400,
    fontSize: '18px',
    fontFamily: 'Nunito',
    lineHeight: '25px',
    color: colors.lightGreen,
};
const customH4 = {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '17px',
    color: colors.lightGreen,
};
const boldH4 = {
    borderBottom: `2px solid ${colors.green}`,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20.72px',
};

const HomeServices = ({ scrollTo }) => {
    const [selectedService, setSelectedService] = useState(1);
    const ref = useRef();
    const isServiceFullyVisible = useIntersection(ref);

    return (
        <Box
            id="services"
            style={{
                backgroundColor: colors.darkViolet,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '48px 24px' }, ...sectionContainer })}>
                <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { margin: '0 10px' } })}>
                    <Typography
                        variant="h2"
                        sx={(theme) => ({ [theme.breakpoints.down('sm')]: { paddingTop: '0px', paddingLeft: '10px' } })}
                    >
                        Servicios
                    </Typography>
                    <Grid
                        container
                        mt={2}
                        sx={(theme) => ({
                            [theme.breakpoints.up('lg')]: { height: '250px' },
                            [theme.breakpoints.down('sm')]: { paddingTop: '24px' },
                            ...servicesContainer,
                        })}
                    >
                        <Grid
                            item
                            lg={7}
                            xs={12}
                            sx={(theme) => ({
                                [theme.breakpoints.down('lg')]: {
                                    borderTopRightRadius: '10px',
                                    borderBottomLeftRadius: '0px',
                                    borderBottom: '0px',
                                },
                                height: '100%',
                                background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
                                borderRadius: '10px',
                                borderTopRightRadius: '0px',
                                borderBottomRightRadius: '0px',
                                border: '2px solid transparent',
                                borderRight: '0px',
                            })}
                            ref={ref}
                        >
                            {selectedService === 1 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '249px' }}>
                                            <StaticImage src="../../images/computer_service_282.svg" alt="Computadora" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                Nubi Compute Service
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Creá tus máquinas virtuales en segundos
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/nubi_compute_service" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedService === 2 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '159px' }}>
                                            <StaticImage src="../../images/flexible-block_storage_icon.svg" alt="Almacenamiento" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                Flexible Block Storage
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Almacenamiento adicional para tus máquinas virtuales
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/flexible_block_storage" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedService === 3 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '178px' }}>
                                            <StaticImage src="../../images/vpc-intro_icon.svg" alt="Nube" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                VPC
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Segmentá tus servidores en distintas redes
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/vpc" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedService === 4 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '205px' }}>
                                            <StaticImage src="../../images/firewall-vpn_icon.svg" alt="Firewall" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                Cloud Firewall and VPN
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Conectate de manera segura a tu nube
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/cloud_firewall_and_vpn" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h5" color={colors.white}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedService === 5 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '220px', paddingRight: '10px' }}>
                                            <StaticImage src="../../images/bucket.svg" alt="Almacenamiento" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                Object Storage Service
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Almacenamiento en Buckets compatible con S3
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/object_storage_service" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedService === 6 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '200px' }}>
                                            <StaticImage src="../../images/bar-graphics.svg" alt="Gráficos" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                Nubi Monitoring Service
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Observá tu infraestructura desde un solo lugar
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/nubi_monitoring_service" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedService === 7 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '249px' }}>
                                            <StaticImage src="../../images/custom_images_icon.svg" alt="Nube" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                Custom Images
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Subí tus imágenes a medida para agilizar tus despliegues
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/custom_images" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedService === 8 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '149px' }}>
                                            <StaticImage src="../../images/dedicated_bandwidth_icon.svg" alt="Velocidad" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                Dedicated Bandwidth
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Enlace dedicado para tu proyecto
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/dedicated_bandwidth" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}

                            {selectedService === 9 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '220px' }}>
                                            <StaticImage src="../../images/ci-cd_icon.svg" alt="Nube" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                CI/CD Devops
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Servicio de DevOps provisto por nuestros ingenieros
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/ci_cd_devops" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedService === 10 && (
                                <Grid container p={4} style={{ height: '100%' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '18px' }, ...imageContainer })}
                                    >
                                        <Box style={{ width: '249px' }}>
                                            <StaticImage src="../../images/migration.svg" alt="Almacenamiento" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ ...titleContainer }}>
                                        <Box>
                                            <Typography variant="h2" color={colors.lightGreen}>
                                                Nubi Migration Service
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Typography variant="h3" style={{ ...customH3 }}>
                                                Migración de datos y aplicaciones 100% gratis
                                            </Typography>
                                        </Box>
                                        <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginTop: '12px' } })}>
                                            <Link to="/services/nubi_migration_service" style={{ textDecoration: 'none' }}>
                                                <Button style={{ paddingLeft: 0 }} aria-label="ver más">
                                                    <Typography variant="h3" style={{ ...customH3, color: colors.white }}>
                                                        Ver más
                                                    </Typography>
                                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>

                        <Grid
                            item
                            lg={5}
                            xs={12}
                            sx={(theme) => ({
                                [theme.breakpoints.down('lg')]: {
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 10,
                                    borderBottomLeftRadius: 10,
                                    height: 'auto',
                                },
                                ...servicesWrapper,
                                padding: '32px 25px 32px 32px',
                                '@media only screen and (min-width: 1200px) and (max-width:1266px)': {
                                    padding: '32px 12px',
                                },
                            })}
                        >
                            <Grid
                                container
                                style={{ height: '100%' }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={(theme) => ({
                                        [theme.breakpoints.up('sm')]: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            paddingLeft: '8px',
                                            paddingBottom: '0px',
                                        },
                                    })}
                                >
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="Nubi compute service"
                                        onClick={() => {
                                            setSelectedService(1);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 1 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            01 Nubi Compute Service
                                        </Typography>
                                    </Button>
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="Flexible Block Storage"
                                        onClick={() => {
                                            setSelectedService(2);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 2 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >

                                            02 Flexible Block Storage
                                        </Typography>
                                    </Button>
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="VPC"
                                        onClick={() => {
                                            setSelectedService(3);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 3 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            03 VPC
                                        </Typography>
                                    </Button>
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="Cloud Firewall and VPN"
                                        onClick={() => {
                                            setSelectedService(4);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 4 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            04 Cloud Firewall and VPN
                                        </Typography>
                                    </Button>
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="Object Storage Service"
                                        onClick={() => {
                                            setSelectedService(5);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 5 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            05 Object Storage Service
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={(theme) => ({
                                        [theme.breakpoints.up('sm')]: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            paddingLeft: '8px',
                                            paddingBottom: '0px',
                                        },
                                    })}
                                >
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="Nubi Monitoring Service"
                                        onClick={() => {
                                            setSelectedService(6);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 6 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            06 Nubi Monitoring Service
                                        </Typography>
                                    </Button>
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="Custom Images"
                                        onClick={() => {
                                            setSelectedService(7);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 7 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            07 Custom Images
                                        </Typography>
                                    </Button>
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="Dedicated Bandwidth"
                                        onClick={() => {
                                            setSelectedService(8);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 8 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            08 Dedicated Bandwidth
                                        </Typography>
                                    </Button>
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="CI/CD Devops"
                                        onClick={() => {
                                            setSelectedService(9);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 9 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            09 CI/CD Devops
                                        </Typography>
                                    </Button>
                                    <Button
                                        style={{ ...selectServiceButton }}
                                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { minHeight: '48px' } })}
                                        aria-label="Nubi Migration Service"
                                        onClick={() => {
                                            setSelectedService(10);
                                            if (!isServiceFullyVisible) scrollTo('#services');
                                        }}
                                    >
                                        <Typography
                                            sx={() => (selectedService === 10 ? { ...boldH4 } : { ...customH4 })}
                                            variant="h4"
                                        >
                                            10 Nubi Migration Service
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default HomeServices;
