import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Carousel, { consts } from 'react-elastic-carousel';

import colors from '../../theme/colors';
import carrouselLogos from '../../helpers/carrousel_logos';

const mainContainer = {
    padding: '60px 8px 0px',
    maxWidth: '1440px',
    width: '100%',
};

const ClientsCarrousel = () => {
    const carouselRef = React.useRef(null);

    const [currentItemsToShow, setCurrentItemsToShow] = React.useState(1);

    const [areArrowsVisible, setAreArrowsVisible] = React.useState(false);
    const customArrow = ({ type, onClick }) => (
        <Button
            onClick={onClick}
            type="button"
        >
            <span>
                {type === consts.PREV
                    ? (
                        <ArrowBackIosIcon
                            sx={() => ({ color: !areArrowsVisible && 'transparent' })}
                        />
                    )
                    : (
                        <ArrowForwardIosIcon
                            sx={() => ({ color: !areArrowsVisible && 'transparent' })}
                        />
                    )}

            </span>
        </Button>
    );

    const breakPoints = [
        { width: 1, itemsToShow: 1, pagination: false },
        { width: 460, itemsToShow: 2, pagination: false },
        { width: 710, itemsToShow: 4, pagination: false },
        { width: 960, itemsToShow: 6, pagination: false },
    ];

    const onResize = (breakpoint) => {
        setCurrentItemsToShow(breakpoint.itemsToShow);
    };

    let resetTimeout;

    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(carrouselLogos.length);
        }
    };

    const onNextEnd = ({ index }) => {
        clearTimeout(resetTimeout);
        if (index + currentItemsToShow === carrouselLogos.length) {
            resetTimeout = setTimeout(() => {
                carouselRef.current.goTo(0);
            }, 2000);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: colors.darkViolet,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: '48px 24px, 12px' }, ...mainContainer })}>
                <Typography
                    variant="h3"
                    mb={2}
                    sx={(theme) => ({ [theme.breakpoints.down('sm')]: { paddingTop: '24px', paddingLeft: '10px' }, paddingLeft: '136px' })}
                >
                    Algunos de nuestros clientes
                </Typography>
                <Box
                    onMouseOver={() => setAreArrowsVisible(true)}
                    onMouseLeave={() => setAreArrowsVisible(false)}
                >
                    <Carousel
                        width="100%"
                        itemPadding={[35, 0]}
                        breakPoints={breakPoints}
                        ref={carouselRef}
                        disableArrowsOnEnd={false}
                        renderArrow={customArrow}
                        isRTL={false}
                        enableAutoPlay
                        enableMouseSwipe={false}
                        onPrevStart={onPrevStart}
                        onNextStart={onNextStart}
                        onNextEnd={onNextEnd}
                        onResize={onResize}
                    >
                        {carrouselLogos}
                    </Carousel>
                </Box>
            </Box>
        </Box>
    );
};

export default ClientsCarrousel;
