/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import {
    Button, Typography, Box, Grid,
} from '@mui/material';
import Typewriter from 'typewriter-effect';
import heroBackground from '../../images/hero-background.svg';
import cloudGIF from '../../images/cloud.gif';
import colors from '../../theme/colors';

const customButton = {
    width: '179px',
    height: '48px',
};
const heroWrapper = {
    maxWidth: '1440px',
    minHeight: '491px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    padding: '85px 136px 0px',
};

const heroContainer = {
    minWidth: '257px',
    maxWidth: '553px',
};
const videoWrapper = {
    justifyContent: 'center',
    alignItems: 'center',
    ml: '30px',
    overflow: 'hidden',
};

const Hero = ({ scrollTo }) => (
    <Box
        style={{
            backgroundColor: '#171623',
            backgroundImage: `url(${heroBackground})`,
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
        }}
    >
        <Box
            sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '117px 24px 24px' }, ...heroWrapper })}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <Box
                        sx={{ ...heroContainer }}
                    >
                        <Box mb={2}>
                            <Typography variant="h2" style={{ fontWeight: 400, fontSize: '22px', fontFamily: 'Nunito' }}>
                                La nube simplificada ¡Accesible para todos!
                            </Typography>
                        </Box>
                        <Box mb={4}>
                            <Typography variant="h1" maxWidth="480px">
                                Infraestructura y servicios en la nube
                                para que puedas
                                {' '}
                                <span style={{ color: colors.green, fontSize: '32px', lineHeight: '47px' }}>
                                    <Typewriter
                                        options={{
                                            strings: ['Desarrollar', 'Probar', 'Desplegar', 'Correr', 'Mantener', 'Innovar'],
                                            autoStart: true,
                                            loop: true,
                                            cursorClassName: 'typewriter_cursor',
                                        }}
                                    />
                                </span>
                            </Typography>
                        </Box>
                        <Button sx={{ ...customButton }} variant="contained" onClick={() => scrollTo('#services')}>
                            Ver servicios
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={(theme) => ({ [theme.breakpoints.down('lg')]: { display: 'none' } })}
                    align="right"
                >
                    <Box
                        sx={{ ...videoWrapper }}
                    >
                        <img
                            loop
                            muted
                            autoPlay
                            playsInline
                            width="400px"
                            alt="Nube"
                            src={cloudGIF}
                        />
                    </Box>
                </Grid>
            </Grid>

        </Box>
    </Box>
);

export default Hero;
