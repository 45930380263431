import React from 'react';
import {
    Typography, Box, Button, Grid,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Link } from 'gatsby';
import colors from '../../theme/colors';
import { solutions } from '../../helpers/home_types';

const sectionContainer = {
    padding: '45px 136px',
    maxWidth: '1440px',
};

const solutionWrapper = {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    marginRight: '10px',
    marginBottom: '10px',
    padding: '32px 18px 20px 24px',
    borderRadius: '10px',
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    position: 'relative',
};

const solutionLogo = {
    width: '96px',
    minHeight: '72px',
    height: '72px',
};

const buttonWrapper = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 20,
    right: 18,
};
const arrowIcon = {
    color: colors.green,
    padding: 0,
    transform: 'scale(0.75)',
};

const HomeSolutions = () => (
    <Box
        style={{
            backgroundColor: colors.darkViolet,
            display: 'flex',
            justifyContent: 'center',
        }}
    >
        <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '48px 24px' }, ...sectionContainer })}>
            <Typography
                variant="h3"
                mb={2}
                sx={(theme) => ({ [theme.breakpoints.down('sm')]: { paddingTop: '24px', paddingLeft: '10px' } })}
            >
                Soluciones
            </Typography>
            <Grid container columns={20}>
                {solutions.map((solution) => (
                    <Grid item xs={20} md={10} lg={4} key={solution.key}>
                        <Box sx={(theme) => ({
                            [theme.breakpoints.up('md')]: { height: '290px' },
                            [theme.breakpoints.down('md')]: { marginRight: '0px' },
                            ...solutionWrapper,
                        })}
                        >
                            <Box sx={{ ...solutionLogo }}>
                                {solution.image}
                            </Box>
                            <Box mt={4}>
                                <Typography variant="h4" style={{ fontSize: '22px', color: colors.lightGreen }}>
                                    {solution.name}
                                </Typography>
                            </Box>
                            <Box mt={2} mb={1} sx={(theme) => ({ [theme.breakpoints.down('lg')]: { paddingBottom: '48px' } })}>
                                <Typography variant="h5" color={colors.lightGreen} fontSize={16}>
                                    {solution.description}
                                </Typography>
                            </Box>
                            <Box sx={{ ...buttonWrapper }}>
                                <Link to={`/solutions/${solution.url_key}`} style={{ textDecoration: 'none' }}>
                                    <Button aria-label="ver más">
                                        <Typography variant="h5" color={colors.white} fontSize={16}>
                                            Ver más
                                        </Typography>
                                        <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    </Box>
);

export default HomeSolutions;
